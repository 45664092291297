import React from 'react';

import Helmet from './helmet';
import Notification from './notification';
import Header from './header';
import Footer from './footer';

import './layout.css';

export default function Layout({ pageInfo, children }) {
  return (
    <div id={pageInfo.id} className="layout">
      <Helmet {...pageInfo} />
      <Notification />
      <Header pageInfo={pageInfo} />
        {children}
      <Footer />
    </div>
  );
}
