import React from 'react';

import './section.css';

export default function Section({ id, colorScheme, children }) {
  const className = colorScheme === 'dark'
    ? 'section section--dark'
    : 'section section--light'

  return (
    <section id={id} className={className}>
      <div className="section__outer-container">
        <div className="section__inner-container">
          <div className="section__content">
            {children}
          </div>
        </div>
      </div>
    </section>
  );
}
