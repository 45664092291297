import React from 'react';
import { Helmet as ReactHelmet } from 'react-helmet';
import { graphql, StaticQuery } from 'gatsby';

import { BASE_URL } from '../data/constants';

export default function Helmet({
  url,
  title,
  description,
  keywords,
  image,
}) {
  return (
    <StaticQuery
      query={graphql`
        query {
          strapiGeneral {
            shortName
          }
        }
      `}
      render={(data) => {
        const fullTitle = `${title} | ${data.strapiGeneral.shortName}`;
        const fullUrl = `${BASE_URL}${url}`;

        return (
          <ReactHelmet
            htmlAttributes={{ lang: 'ru-RU' }}
            script={[
              {
                // Yandex Metrika
                type: 'text/javascript',
                innerHTML: `(function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)}; m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)}) (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym"); ym(68627854, "init", { clickmap:true, trackLinks:true, accurateTrackBounce:true, webvisor:true });`
              },
              {
                // Facebook Pixel
                type: 'text/javascript',
                innerHTML: `!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window, document,'script','https://connect.facebook.net/en_US/fbevents.js');fbq('init', '714222425960045');fbq('track', 'PageView');`
              }
            ]}
          >
            {/* <link rel="canonical" href={fullUrl} /> */}

            <title>{fullTitle}</title>

            <meta name="description" content={description} key="description" />
            <meta name="keywords" content={keywords} />

            <meta property="og:url" content={fullUrl} key="og:url" />
            <meta property="og:title" content={fullTitle} key="og:title" />
            <meta property="og:description" content={description} key="og:description" />
            {image && <meta property="og:image" content={image} key="og:image" />}

            <meta name="twitter:card" content="summary_large_image" />
            <meta property="twitter:domain" content="kmk.clinic" />
            <meta property="twitter:url" content={fullUrl} />
            <meta name="twitter:title" content={fullTitle} />
            <meta name="twitter:description" content={description} />
            {image && <meta name="twitter:image" content={image} />}
          </ReactHelmet>
        );
      }}
    />
  );
}
