import React from 'react';
import { FiX } from 'react-icons/fi';
import { graphql, StaticQuery } from 'gatsby';

import './notification.css';

class Notification extends React.Component {
  state = {
    visible: false,
    isMobile: false,
  };

  ref = React.createRef();

  timeout = null;

  componentDidMount() {
    const isNotified = !!this.props.data.allStrapiNotification.edges.length
      && this.props.data.allStrapiNotification.edges[0].node.title !== 'DUMMY';
    const isViewed = sessionStorage.getItem('isViewed');

    if (isNotified && !isViewed) {
      this.enter();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  animate = (animation) => new Promise((resolve, reject) => {
    const node = this.ref.current;
    const animationName = `animate__${animation}`;
    const classList = ['animate__animated', animationName];

    node.classList.add(...classList);

    const handleAnimationEnd = (event) => {
      event.stopPropagation();
      node.classList.remove(...classList);
      resolve();
    };

    node.addEventListener('animationend', handleAnimationEnd, { once: true });
  });

  enter = () => {
    this.timeout = setTimeout(() => {
      const animation = window.innerWidth <= 414
        ? 'slideInUp'
        : 'slideInRight';

      this.setState({ visible: true });
      this.animate(animation);
    }, 1500);
  };

  exit = () => {
    const animation = window.innerWidth <= 414
      ? 'slideOutDown'
      : 'slideOutRight';

    this.animate(animation)
      .then(() => this.setState({ visible: false }));
  };

  onRedirect = (event) => {
    event.stopPropagation();

    const [notification] = this.props.data.allStrapiNotification.edges;

    if (notification && notification.node.link) {
      sessionStorage.setItem('isViewed', true);
      window.location.href = notification.node.link;
    }
  };

  onClose = (event) => {
    event.stopPropagation();

    sessionStorage.setItem('isViewed', true);    
    this.exit();
  };

  render() {
    const [notification] = this.props.data.allStrapiNotification.edges;

    if (!notification || notification.node.title === 'DO NOT TOUCH' || !this.state.visible) {
      return null;
    }

    return (
      <div
        ref={this.ref}
        className="notification"
        onClick={this.onRedirect}
      >
        <div>
          <div className="notification__title">
            {notification.node.title || 'Дорогой клиент!'}
          </div>
          <div className="notification__content">
            {notification.node.content}
          </div>
          {notification.node.link && (
            <span className="notification__link">
              {notification.node.linkTitle || 'Подробнее'}
            </span>
          )}
        </div>
        <button
          type="button"
          className="notification__close-btn"
          onClick={this.onClose}
        >
          <FiX
            style={{ verticalAlign: 'middle', width: '24px', height: '24px' }}
            color="#ffffff"
          />
        </button>
      </div>
    );
  }
}

const NotificationWithData = () => (
  <StaticQuery
    query={graphql`
      query {
        allStrapiNotification(sort: { fields: date, order: DESC }, limit: 1) {
          edges {
            node {
              strapiId
              title
              content
              linkTitle
              link
              date(formatString: "DD.MM.YYYY")
            }
          }
        }
      }
    `}
    render={(data) => <Notification data={data} />}
  />
);

export default NotificationWithData;
