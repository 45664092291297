import React from 'react';
import {
  FiPhone,
  FiMapPin,
  FiClock,
  FiChevronRight,
  FiArrowRightCircle,
} from 'react-icons/fi';
import { graphql, StaticQuery } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import Nav from './nav';

import BackgroundHero from '../assets/svg/background-hero.svg';

import './header.css';

export default class Header extends React.Component {
  renderPageInfo(pageInfo) {
    const destination = pageInfo.breadcrumbs.pop();

    const breadcrumbs = pageInfo.breadcrumbs
      .map((breadcrumb) => (
        <React.Fragment key={breadcrumb.title}>
          <a className="header__link" href={breadcrumb.link}>
            {breadcrumb.title}
          </a>
          <span className="header__chevron">
            <FiChevronRight
              size="16px"
              color="#ffffff"
            />
          </span>
        </React.Fragment>
      ))
      .concat((
        <span className="header__destination" key={destination.title}>
          {destination.title}
        </span>
      ));

    return (
      <div className="header__page-info">
        <div className="header__title">
          {pageInfo.title}
        </div>
        <div className="header__breadcrumbs">
          {breadcrumbs}
        </div>
      </div>
    );
  }

  render() {
    const { pageInfo } = this.props;

    return (
      <StaticQuery
        query={graphql`
          query {
            strapiGeneral {
              phone
              address
              weekdaysWorkingHours
            }
          }
        `}
        render={(data) => (
          <header id="header" className="header">
            <div className="header__outer-container">
              {
                pageInfo.id === 'index-page'
                  ? (
                    <div className="header__background-hero">
                      <BackgroundHero />
                    </div>
                  )
                  : null
              }
              <div className="header__inner-container">
                <section className="header__section">
                  <span className="header__el">
                    <span className="header__contact-info-icon">
                      <FiPhone
                        style={{ verticalAlign: 'middle', width: '24px', height: '24px' }}
                        color="#2055a4"
                      />
                      &nbsp;&nbsp;
                    </span>
                    <span className="header__contact-info-label">
                      Телефон:&nbsp;
                    </span>
                    <span className="header__contact-info-value">
                      <a href={`tel:${data.strapiGeneral.phone}`}>{data.strapiGeneral.phone}</a>
                    </span>
                  </span>
                  <span className="header__el">
                    <span className="header__contact-info-icon">
                      <FiMapPin
                        style={{ verticalAlign: 'middle', width: '24px', height: '24px' }}
                        color="#2055a4"
                      />
                      &nbsp;&nbsp;
                    </span>
                    <span className="header__contact-info-label">
                      Адрес:&nbsp;
                    </span>
                    <span className="header__contact-info-value">
                      {data.strapiGeneral.address}
                    </span>
                  </span>
                  <span className="header__el">
                    <span className="header__contact-info-icon">
                      <FiClock
                        style={{ verticalAlign: 'middle', width: '24px', height: '24px' }}
                        color="#2055a4"
                      />
                      &nbsp;&nbsp;
                    </span>
                    <span className="header__contact-info-label">
                      Ежедневно:&nbsp;
                    </span>
                    <span className="header__contact-info-value">
                      {data.strapiGeneral.weekdaysWorkingHours}
                    </span>
                  </span>
                </section>
                <section className="header__section">
                  <Nav />
                </section>
                <section className="header__section">
                  {this.renderPageInfo(pageInfo)}
                </section>
                {
                  pageInfo.id === 'index-page'
                    ? (
                      <div className="row clearfix header__hero">
                        <div className="column column--1-of-2 header__hero-column header__hero-column--mobile-only">
                          <StaticImage
                            className="header__hero-img"
                            src="../assets/img/hero.png"
                            alt="Hero"
                          />
                        </div>
                        <div className="column column--1-of-2 header__hero-column">
                          <div className="header__hero-txt">
                            <div className="header__title header__title--secondary">
                              Лечение без операций
                            </div>
                            <div className="header__title header__title--primary">
                              HIFU-терапия
                            </div>
                            <div className="header__description">
                              Высокотехнологичный инновационный метод лечения различных доброкачественных
                              новообразований фокусированным ультразвуком высокой частоты.
                            </div>
                            <a
                              className="btn btn--light"
                              href="/services/hifu-terapiya"
                            >
                              <span className="btn__text">
                                Подробнее
                              </span>
                              <span className="btn__icon">
                                <FiArrowRightCircle
                                  width="20px"
                                  height="20px"
                                  style={{ marginTop: '.4rem' }}
                                  // color="#ffffff"
                                />
                              </span>
                            </a>
                          </div>
                        </div>
                        <div className="column column--1-of-2 header__hero-column header__hero-column--desktop-only">
                          <StaticImage
                            className="header__hero-img"
                            src="../assets/img/hero.png"
                            alt="Hero"
                          />
                        </div>
                      </div>
                    )
                    : null
                }
              </div>
            </div>
          </header>
        )}
      />
    );
  }
}
