import React from 'react';

import './footer.css';

export default function Footer() {
  return (
    <footer id="footer" className="footer">
      <div className="footer__outer-container">
        <div className="footer__inner-container">
          {`Клиника Молекулярной Коррекции ${String.fromCharCode(169)} ${new Date().getFullYear()}`}
        </div>
      </div>
    </footer>
  );
}
