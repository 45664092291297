import React from 'react';
import {
  FiMenu,
  FiX,
} from 'react-icons/fi';
import { StaticImage } from 'gatsby-plugin-image';

import './nav.css';

export default class Nav extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isClosed: true,
    };
  }

  onMobileNavToggle() {
    this.setState((state) => ({ isClosed: !state.isClosed }));
  }

  render() {
    const { isClosed } = this.state;

    return (
      <nav id="nav" className="nav clearfix">
        <span className="nav__el">
          <a className="nav__logo-link" href="/">
            <span className="nav__logo nav__logo--light">
              <StaticImage
                src="../assets/img/logo-light.png"
                alt="KMK Clinic"
              />
            </span>
            <span className="nav__logo nav__logo--dark">
              <StaticImage
                src="../assets/img/logo-dark.png"
                alt="KMK Clinic"
              />
            </span>
          </a>
        </span>
        <span className="nav__el nav__desktop-menu">
          <ul className="nav__ul">
            <li className="nav__li">
              <a className="nav__a" href="/">Главная</a>
            </li>
            <li className="nav__li">
              <a className="nav__a" href="/services">Услуги и цены</a>
            </li>
            <li className="nav__li">
              <a className="nav__a nav__a--red" href="/offers">Акции</a>
            </li>
            <li className="nav__li">
              <a className="nav__a" href="/news">Новости</a>
            </li>
            <li className="nav__li">
              <a className="nav__a" href="/team">Наш коллектив</a>
            </li>
            <li className="nav__li">
              <a className="nav__a" href="/about">О клинике</a>
            </li>
            <li className="nav__li">
              <a className="nav__a" href="/policy">Политика</a>
            </li>
            <li className="nav__li">
              <a className="nav__a" href="/contacts">Контакты</a>
            </li>
          </ul>
        </span>
        <span className="nav__el nav__hamburger">
          <button
            type="button"
            onClick={() => this.onMobileNavToggle()}
          >
            <FiMenu
              size="24px"
              // color="#2055a4"
            />
          </button>
        </span>
        <div
          className={
            isClosed
              ? 'nav__mobile-menu nav__mobile-menu--closed'
              : 'nav__mobile-menu'
          }
        >
          <div className="nav__mobile-menu__header clearfix">
            <a className="nav__mobile-menu__logo-link" href="/">
              <span className="nav__mobile-menu__logo nav__mobile-menu__logo--dark">
                <StaticImage
                  src="../assets/img/logo-dark.png"
                  alt="KMK Clinic"
                />
              </span>
            </a>
            <button
              type="button"
              onClick={() => this.onMobileNavToggle()}
            >
              <FiX
                size="24px"
                // color="#2055a4"
              />
            </button>
          </div>
          <ul className="nav__mobile-menu__ul">
            <li className="nav__mobile-menu__li">
              <a className="nav__mobile-menu__a" href="/">Главная</a>
            </li>
            <li className="nav__mobile-menu__li">
              <a className="nav__mobile-menu__a" href="/services">Услуги и цены</a>
            </li>
            <li className="nav__mobile-menu__li">
              <a className="nav__mobile-menu__a nav__mobile-menu__a--red" href="/offers">Акции</a>
            </li>
            <li className="nav__mobile-menu__li">
              <a className="nav__mobile-menu__a" href="/news">Новости</a>
            </li>
            <li className="nav__mobile-menu__li">
              <a className="nav__mobile-menu__a" href="/team">Наш коллектив</a>
            </li>
            <li className="nav__mobile-menu__li">
              <a className="nav__mobile-menu__a" href="/about">О клинике</a>
            </li>
            <li className="nav__mobile-menu__li">
              <a className="nav__mobile-menu__a" href="/policy">Политика</a>
            </li>
            <li className="nav__mobile-menu__li">
              <a className="nav__mobile-menu__a" href="/contacts">Контакты</a>
            </li>
          </ul>
        </div>
      </nav>
    );
  }
}
